import styled from "styled-components";
import { color, columnBreakPoint, defaultFont } from "../../globalStyleVariables";

export const StyledButton = styled.button`
    cursor: pointer;
    width: ${({ size }) => size || "100%"};
    background: ${color.main};
    border-radius: ${({ radius }) => radius || 5}px;
    color: #fff;
    padding: 8px 0;
    /* font-weight: 700; */
    font-size: 1.1rem;
    transition: 0.2s ease-in-out;
    box-shadow: 0 5px 15px rgba(${color.mainRGB}, 0.25);
    font-family: ${defaultFont};

    &:hover,
    &:focus {
        background: ${color.mainHover};
    }

    &:disabled {
        background: ${color.placeholder};
        color: ${color.text};
        cursor: no-drop;
    }

    @media only screen and (max-width: ${columnBreakPoint}px) {
        ${({ mobile }) => mobile};
    }
`;
