import styled from "styled-components";
import { color } from "../../globalStyleVariables";

export const InputOuterContainer = styled.div`
    display: flex;
    box-shadow: 0 7.5px 10px rgba(204, 204, 204, 0.25);

    .password-view__container {
        cursor: pointer;
        width: 3rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid #e6e6e6;

        transition: 0.2s ease-in-out;
        color: ${({ active }) => (active ? color.success : color.text)};

        &:hover {
            color: ${({ active }) => (active ? color.success : color.title)};
        }
    }
`;

export const InputContainer = styled.div`
    position: relative;
    /* margin-bottom: 1.25rem; */
    width: 100%;
    display: flex;
`;

const inputSideSpacing = 15;

const activeLabelStyle = {
    width: "auto",
    left: "8px",
    "font-size": "0.725rem",
    transition: "0.3s",
    top: "13.5px",
    padding: "0 8px !important",
    "z-index": "2",
    "letter-spacing": ".25px"
    // background: "${color.white}"
};

export const TextInput = styled.input`
    border: 1px solid #e6e6e6;
    border-radius: 5px;

    background: ${color.white};
    font-size: 0.925rem;
    outline: none;

    width: 100%;
    padding: 20px 15px 5px;

    .has-content& ~ span i:before {
        height: 100%;
        transition: 0.4s;
    }

    &:focus ~ span:before,
    &:focus ~ span:after {
        width: 100%;
        transition: 0.3s;
    }

    & ~ label.error ~ span i:before {
        background: ${color.error} !important;
    }

    &:focus ~ span i:before,
    &:focus ~ span i:after {
        height: 100%;
        transition: 0.4s;
    }

    .has-content& ~ label {
        ${activeLabelStyle}
        color: ${color.text};
    }

    &:focus ~ label {
        ${activeLabelStyle}
        color: ${color.main};
    }
`;

export const StyledTextarea = styled.textarea`
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    background: ${color.white};
    font-size: 0.925rem;

    width: 100%;
    padding: 20px 15px 5px;

    .has-content& ~ span i:before {
        height: 100%;
        transition: 0.4s;
    }

    &:focus ~ span:before,
    &:focus ~ span:after {
        width: 100%;
        transition: 0.3s;
    }

    & ~ label.error ~ span i:before {
        background: ${color.error} !important;
    }

    &:focus ~ span i:before,
    &:focus ~ span i:after {
        height: 100%;
        transition: 0.4s;
    }

    .has-content& ~ label {
        ${activeLabelStyle}
        color: ${color.text};
    }

    &:focus ~ label {
        ${activeLabelStyle}
        color: ${color.main};
    }

    resize: vertical;
    min-height: 100px;
    max-height: 250px;
`;

export const FocusBorder = styled.span`
    z-index: 1;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: ${color.main};
        transition: 0.3s;
    }

    &:after {
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
    }

    & i:before,
    & i:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 0;
        background: ${color.main};
        transition: 0.4s;
    }

    & i:after {
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
    }
`;

export const Label = styled.label`
    position: absolute;
    left: ${inputSideSpacing}px;
    width: 100%;
    top: ${({ textarea }) => (textarea ? "1.5rem" : "50%")};
    /* font-weight: 700; */
    transform: translateY(-50%);
    color: #ccc;
    transition: 0.3s;
    word-spacing: -0.05rem;
    pointer-events: none;
`;
