import styled, { keyframes } from "styled-components";
import { color, columnBreakPoint } from "../../globalStyleVariables";

const entryAnimation = keyframes`
   0% {
      opacity: 0;
      transform: translateY(-25px);
   }

   100% {
      opacity: 1;
   }
`;

const exitAnimation = keyframes`
   0% {
      opacity: 0;
      transform: translateY(25px);
   }

   100% {
      opacity: 1;
   }
`;

export const VerifyEmailStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2F55D4;
    height: 100vh;
    font-weight: 900;
    font-size: 28px;
`;

export const Wrapper = styled.div`
    animation: ${({ animation, reverse }) => (animation && reverse ? exitAnimation : animation && !reverse ? entryAnimation : "")} 0.5s ease-in-out;
`;

export const Container = styled.form`
    display: flex;
    height: 100vh;
    background: ${color.background};

    @media only screen and (max-width: 1100px) {
        height: auto;
        flex-direction: column;
    }

    .select {
        &-container {
            width: 100%;
            margin-bottom: 1.5rem;
            box-shadow: 0 7.5px 10px rgba(#ccc, 0.25);
        }
    }

    .input-container {
        margin-bottom: 1rem;
    }

    .error {
        color: ${color.error} !important;
    }

    .select-container.error {
        border-left: 2px solid ${color.error};

        .select__control {
            border-radius: 0 5px 5px 0;
        }
    }
`;

export const Logo = styled.h1`
    text-align: center;
    font-size: 2rem;
`;

// register box
export const RegisterContainer = styled.section`
    width: 60%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* animation: ${entryAnimation} 0.5s ease-in-out; */

    .sub-title {
        font-size: 1rem;
        color: ${color.text};
        text-align: center;
        line-height: 1.7;

        &:not(:first-child) {
            margin-top: 1rem;
        }

        b {
            font-weight: 700;
            color: ${color.main};
        }
    }

    .register-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 1rem;

        .register-bottom-confirm {
            background-color: #2f55d4;
            padding: 5px 10px;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
        }

        a {
            cursor: pointer;
            color: ${color.main};
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 740px) {
        width: 90vw;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        align-items: flex-start;
        padding: 2rem;

        .sub-title {
            text-align: left;
        }
    }
`;

export const Title = styled.h1`
    font-size: 1.7rem;
    font-weight: 900;
    letter-spacing: 0.4px;
    margin-bottom: 1.2rem;
    color: ${color.title};
    text-align: center;
`;

export const Line = styled.hr`
    border: 1px solid #e6e6e6;
    margin: 0 auto;
    opacity: 0.5;
    margin: 1.5rem auto;
    width: 40%;
`;

export const StyledBanner = styled.section`
    position: relative;
    color: ${color.white};
    padding: 4rem;
    width: 40%;
    background: ${color.main};
    background: linear-gradient(117deg, ${color.main} 0%, rgba(137, 160, 238, 1) 100%);
    border-radius: 0 15px 0 0;

    .logo {
        display: inline-block;
        color: ${color.white};

        font-size: 1.5rem;
        font-weight: 900;
        margin-bottom: 6rem;
        transition: 0.2s ease-in-out;

        &:hover {
            color: ${color.whiteHover};
        }
    }

    .title {
        font-size: 2rem;
        letter-spacing: 0.5px;
        font-weight: 700;
    }

    .content {
        margin-top: 2rem;
        line-height: 1.6;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding: 2rem;
        border-radius: 0;
        height: 70vh;

        .logo {
            text-align: center;
            margin-bottom: 3rem;
        }
    }
`;
