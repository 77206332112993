import React, { useState } from "react";

import { color, selectStyles } from "../../../globalStyleVariables";

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import { Link } from "react-router-dom";

import { Title, Line, Wrapper } from "../styles";

import Select from "react-select";

function StepThree({ userInfo, setStep, setUserInfo, animation }) {
    const [selectedOptions, setSelectedOptions] = useState({});
    const [error, setError] = useState({
        enterpriseName: false,
        officerQuantity: false,
        organizationType: false
    });

    const enterpriseNameInput = React.createRef();

    const quantityOptions = [
        { value: "1-30", label: "1 - 10" },
        { value: "11-30", label: "11 - 30" },
        { value: "31-100", label: "31 - 100" },
        { value: "100+", label: "Acima de 100" }
    ];

    const organizationOptions = [
        { value: "Tecnologia", label: "Tecnologia" },
        { value: "Logistica", label: "Logistica" },
        { value: "Hotelaria", label: "Hotelaria" },
        { value: "Agropecuaria", label: "Agropecuaria" }
    ];

    const validateForm = (e) => {
        e.preventDefault();

        const enterpriseName = enterpriseNameInput.current.value;
        const { officerQuantity, organizationType } = selectedOptions;

        if (enterpriseName && officerQuantity && organizationType) {
            setUserInfo((prev) => ({
                ...prev,
                enterpriseName,
                officerQuantity,
                organizationType
            }));

            return setStep((prev) => Number(prev) + 1);
        }

        if (!enterpriseName) setError((prev) => ({ ...prev, enterpriseName: true }));
        if (!officerQuantity) setError((prev) => ({ ...prev, officerQuantity: true }));
        if (!organizationType) setError((prev) => ({ ...prev, organizationType: true }));
    };

    return (
        <Wrapper animation={animation}>
            <Title>Quase lá...</Title>

            <p className="sub-title">
                Preencha as principais informações de sua empresa para que possamos indicar a você as melhores features para sua gestão.
            </p>

            <Line />

            <Input
                label="Nome da empresa"
                ref={enterpriseNameInput}
                className={error.enterpriseName && "error"}
                autofocus
                defaultValue={userInfo.enterpriseName || ""}
            />
            <Select
                options={quantityOptions}
                className={`select-container ${error.officerQuantity && "error"}`}
                classNamePrefix="select"
                placeholder="Quantidade de Funcionários"
                styles={selectStyles}
                onChange={(e) => setSelectedOptions((prev) => ({ ...prev, officerQuantity: e }))}
            />

            <Select
                options={organizationOptions}
                className={`select-container ${error.organizationType && "error"}`}
                classNamePrefix="select"
                placeholder="Tipo de Organização"
                styles={selectStyles}
                onChange={(e) => setSelectedOptions((prev) => ({ ...prev, organizationType: e }))}
            />

            <div className="register-bottom">
                <Link to="#" onClick={() => setStep((prev) => Number(prev) - 1)}>
                    Voltar
                </Link>
                <Button value="Continuar" size={"200px"} cb={validateForm} />
            </div>
        </Wrapper>
    );
}

export default StepThree;
