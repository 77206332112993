import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VerifyEmailStyles } from "../styles";

function VerifyEmail() {

    const params = useParams();

    const [isCormfirmed, setIsConfirmed] = useState(null);

    useEffect(()=>{
        const _userId = params.userid;
        const _hash = params.hash;

        axios.post(`${process.env.REACT_APP_HOST}/auth/confirmemail`, {
            id: _userId,
            hash: _hash
        }).then((res)=>{
            if(res.data.status == true) {
                setIsConfirmed(true)
            }
        }).catch((err) => {
            setIsConfirmed(false)
        })
            // .then(function (response) {
            //     localStorage.setItem("userData", JSON.stringify(response.data.user));
            //     setRedirect(true);
            // })
            // .catch(function (error) {
            //     console.error("erro ao validar e-mail", error);
            // });
    }, [])
    
    if (isCormfirmed === null) {
        return (
            <VerifyEmailStyles>
                <span>Confirmando ...</span>
            </VerifyEmailStyles>
        );
    } else if (isCormfirmed === false) {
        return (
            <VerifyEmailStyles>
                <span>Algo de errado aconteceu.</span>
            </VerifyEmailStyles>
        );
    }else if (isCormfirmed === true) {
        return (
            <VerifyEmailStyles>
                <span>Confirmado, volte na pagina e prossiga.</span>
            </VerifyEmailStyles>
        );
    }
}

export default VerifyEmail;
