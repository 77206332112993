import React, { Component } from "react";
import { Link } from "react-router-dom";

//partials
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import Button from "../../components/Button";

//styles
import "./styles.scss";

// icons
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

export class Plans extends Component {
    render() {
        return (
            <>
                <main className="e-main-page e-page-plans">
                    <Header />

                    <section className="e-plans">
                        <div className="e-plans__container">
                            <h1 className="e-title">Nossos Planos</h1>

                            <div className="e-plans__boxs">
                                <div className="e-box">
                                    <h4 className="e-box__title">Grátis</h4>

                                    <h5 className="e-box__price">R$ 0</h5>

                                    <ul>
                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Até 3 usuários
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Até 30 tarefas abertas ao mês
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Até 5 quadros
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Anexos de até 20 MB
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-not"><RemoveCircleOutlineRoundedIcon/></span> Relatórios ilimitados
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-not"><RemoveCircleOutlineRoundedIcon/></span> Suporte por chat, telefone e e-mail
                                        </li>
                                    </ul>

                                    <div className="e-btn-action">
                                        <Link to="/register">
                                            <Button value="Comece Grátis" size="180px" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="e-box">
                                    <h4 className="e-box__title">Business</h4>

                                    <h5 className="e-box__price">
                                        R$ 29 <span>Por usuário / mês</span>
                                    </h5>

                                    <ul>
                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Usuários ilimitados
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Tarefas abertas ilimitadas
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Quadros ilimitados
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Anexos de até 5 GB
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Relatórios ilimitados
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Suporte por chat, telefone e
                                            e-mail
                                        </li>
                                    </ul>

                                    <div className="e-btn-action">
                                        <Link to="/register">
                                            <Button value="Começar" size="250px" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="e-box">
                                    <h4 className="e-box__title">Enterprise</h4>

                                    <h5 className="e-box__price">
                                        R$ 69 <span>Por usuário / mês</span>
                                    </h5>

                                    <ul>
                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Todos os recursos do plano
                                            Bussines
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Convites de domínio restrito
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Backup e log das atividades
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Treinamento remoto
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Configuração inicial do dashboard
                                        </li>

                                        <li>
                                            <span className="material-icons e-icon-done"><CheckCircleOutlineRoundedIcon/></span>Reuniões presenciais
                                        </li>
                                    </ul>

                                    <div className="e-btn-action">
                                        <Link to="/register">
                                            <Button value="Começar" size="180px" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                </main>
            </>
        );
    }
}

export default Plans;
