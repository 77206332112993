import React from "react";

// import "../../styles.scss";

function Cards() {
    const cards = [
        {
            title: "Times",
            description:
                "Facilite a interação entre os times de sua corporação e dê uma visão ampla a todos de tudo o que acontece em sua corporação."
        },
        {
            title: "Projetos",
            description: "Trabalhe com qualquer projeto e otimize os processos da sua corporação com demandas especificas por projeto."
        },
        {
            title: "Quadros",
            description: "Deixe que seus times crie quadros de tarefas da maneira que necessitam. De liberdade e eficiente à todos."
        },
        {
            title: "Tarefas",
            description: "Abra as tarefas de forma simples, rápida e complete com as principais informações que sua equipe se desejar."
        },
        {
            title: "Play nas Tarefas",
            description: "O Responsavel pela execução das tarefas tem a permissão para dar play nas tarefas e mensurar o tempo gasto em sua execução."
        },
        {
            title: "Comentários nas Tarefas",
            description:
                "Deixe que seus colaboradores façam comentários nas tarefas, e sejam avisados via alertas de emails aos responsaveis pelas tarefas."
        },
        {
            title: "Anexos nas Tarefas",
            description:
                "Anexar arquivos nas tarefas é tão bom, quanto visualiza-los dentro do Tarefy, assim como é possivel também fazer o seu download."
        },
        {
            title: "Clientes",
            description:
                "Cadastre seus clientes em sua corporação para que seus times trabalhe para os seus clientes, e ao final você mensure o que foi trabalhado."
        },
        {
            title: "Usuarios",
            description: "Adicione à sua corporação usuarios administradores, gestores, ou default."
        },
        {
            title: "Perfis dos Usuarios",
            description:
                "Adicione usuario em seu respectivo perfil, desde acesso full a corporação, gestores ou usuarios default com o básico de visualização."
        },
        {
            title: "Logs do Sistema",
            description:
                "Tenha acesso ao log de informações de tudo o que acontece por tras dos bastidores da corporação, e saiba se esta tudo sob controle."
        },
        {
            title: "Relatórios",
            description:
                "Acompanhe os seus times, clientes, colaboradores e outras métricas de sua corporação com os principais relatórios mensurados pelo Tarefy."
        }
    ];

    return (
        <>
            {cards.map(({ title, description }, i) => (
                <div className="e-box" key={i}>
                    <span className="e-box__icon"></span>
                    <h4 className="e-box__title">{title}</h4>
                    <p>{description || "Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet"}</p>
                </div>
            ))}
        </>
    );
}

export default Cards;
