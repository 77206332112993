import React from "react";

// import "../../styles.scss";

function Resources() {
    const resources = [
        {
            title: "Times",
            description:
                "Facilite a interação entre os times de sua corporação e dê uma visão ampla a todos de tudo o que acontece em sua corporação."
        },
        {
            title: "Projetos",
            description: "Trabalhe com qualquer projeto e otimize os processos da sua corporação com demandas especificas por projeto."
        },
        {
            title: "Quadros",
            description: "Deixe que seus times crie quadros de tarefas da maneira que necessitam. De liberdade e eficiente à todos."
        },
        {
            title: "Tarefas",
            description: "Abra as tarefas de forma simples, rápida e complete com as principais informações que sua equipe se desejar."
        },
        {
            title: "Play nas Tarefas",
            description: "O Responsavel pela execução das tarefas tem a permissão para dar play nas tarefas e mensurar o tempo gasto em sua execução."
        },
        {
            title: "Relatórios",
            description:
                "Acompanhe os seus times, clientes, colaboradores e outras métricas de sua corporação com os principais relatórios mensurados pelo Tarefy."
        }
    ];

    return (
        <section className="e-resources">
            <div className="e-resources__container">
                <h3 className="e-title">Conheça alguns recursos</h3>

                <div className="e-resources__boxs">
                    {resources.map(({ title, description }, i) => (
                        <div className="e-box" key={i}>
                            <span className="e-box__icon"></span>
                            <h4 className="e-box__title">{title}</h4>
                            <p>
                                {description || "Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet"}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Resources;
