import React from "react";

import { Container, ProgressCheck, Line } from "./styles";
import DoneIcon from "@mui/icons-material/Done";

function ProgressBar({ step }) {

    return (
        <Container>
            <ProgressCheck active={step >= 1} top content="Comece agora">
                <DoneIcon className="progress__done-icon" />
            </ProgressCheck>

            <Line active={step >= 2} />

            <ProgressCheck active={step >= 2} bottom content="Confirme seu email">
                <DoneIcon className="progress__done-icon" />
            </ProgressCheck>

            <Line active={step >= 3} />

            <ProgressCheck active={step >= 3} top content="Continue seu cadastro">
                <DoneIcon className="progress__done-icon" />
            </ProgressCheck>

            <Line active={step >= 4} />

            <ProgressCheck active={step >= 4} bottom content="Quase lá...">
                <DoneIcon className="progress__done-icon" />
            </ProgressCheck>

            <Line active={step >= 5} />

            <ProgressCheck active={step >= 5} top content="Para Finalizar">
                <DoneIcon className="progress__done-icon" />
            </ProgressCheck>
        </Container>
    );
}

export default ProgressBar;
