export const color = {
   main: "#2f55d4",
   mainRGB: "47,85,212",
   mainHover: "#2443ac",
   secondary: "#13B15F",
   secondaryHover: "#36c97c",
   text: "#8492a6",
   placeholder: "#ccc",
   title: "#353c50",
   background: "#f6f7fa",
   white: "#fff",
   whiteHover: "#d5ddf6",
   error: "#ff5454",
   medium: "#ffb44d",
   success: "#13B15F",
   neutral: "#9497a2"
};

export const columnBreakPoint = 900;
export const defaultFont = '"Nunito", sans-serif';

export const selectStyles = {
    option: (provided, { isSelected }) => ({
        ...provided,
        color: isSelected ? color.white : color.title,
        background: isSelected ? "rgba(47, 85, 212, .75)" : ""
    }),
    singleValue: (provided) => {
        const transition = "opacity 300ms";

        return { ...provided, transition };
    }
};
