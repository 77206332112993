import styled, { keyframes } from "styled-components";
import { color } from "../../globalStyleVariables";

export const CheckboxContainer = styled.div`
   margin-bottom: 2rem;
`;

const check = keyframes`
  50% {
    transform: scale(1.2);
  }
`;

export const CheckboxInput = styled.input`
   &:checked + label {
      span {
         &:first-child {
            border-color: ${color.main};
            background: ${color.main};
            animation: ${check} 0.6s ease;
         }

         svg {
            stroke-dashoffset: 0;
         }

         &:before {
            transform: scale(2.2);
            opacity: 0;
            transition: all 0.6s ease;
         }

         /* label active */
         &:last-child {
            color: ${color.main};
            transition: all 0.3s ease;
         }
      }
   }
`;

export const CheckboxLabel = styled.label`
   -webkit-user-select: none;
   user-select: none;
   -webkit-tap-highlight-color: transparent;
   cursor: pointer;

   span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);

      &:first-child {
         position: relative;
         width: 24px;
         height: 24px;
         border-radius: 50%;
         transform: scale(1);
         vertical-align: middle;
         border: 1px solid ${color.title};
         transition: all 0.2s ease;

         svg {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
            left: 50%;
            fill: none;
            stroke: white;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all 0.3s ease;
            transition-delay: 0.1s;
         }

         &:before {
            content: "";
            width: 100%;
            height: 100%;
            background: #506eec;
            display: block;
            transform: scale(0);
            opacity: 1;
            border-radius: 50%;
            transition-delay: 0.2s;
         }
      }

      &:last-child {
         margin-left: 8px;
      }
   }

   &:hover span:first-child {
      border-color: ${color.main};
   }
`;

export const CheckboxContent = styled.span`
   color: ${color.text};

   > a {
      color: ${color.main};
      text-decoration: underline;
      font-weight: 700;
      transition: 0.2s ease-in-out;

      &:hover {
         color: ${color.title};
      }
   }
`;
