import React from "react";
import { StyledBurger } from "../../styles";

function Burger({ open, setOpen }) {
   return (
      <StyledBurger
         className="burger"
         open={open}
         onClick={() => setOpen(!open)}
      >
         <div />
         <div />
         <div />
      </StyledBurger>
   );
}

export default Burger;
