import React, { useState } from "react";
import { Link } from "react-router-dom";

import Burger from "./components/Burger";
import Menu from "./components/Menu";

import { Logo, HeaderContainer, StyledHeader } from "./styles";

const Header = ({ logoOnly, logoAlignment }) => {
    const [open, setOpen] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    window.onscroll = () => setIsScrolling(window.scrollY >= 100);
    let justifyContent = logoAlignment;

    if (logoAlignment == "left") justifyContent = "flex-start";
    if (logoAlignment == "right") justifyContent = "flex-end";

    return (
        <StyledHeader
            className="e-header"
            style={{
                boxShadow: isScrolling && "5px 5px 15px rgba(44, 53, 84, .2)"
            }}
        >
            <HeaderContainer className="e-header__container" style={{ justifyContent }}>
                <Logo className="e-header__logo">
                    <Link to="/" className="e-logo">
                        Tarefy
                    </Link>
                </Logo>

                {!logoOnly && (
                    <>
                        <Burger open={open} setOpen={setOpen} onClick={() => setOpen(!open)} />

                        <Menu open={open} />
                    </>
                )}
            </HeaderContainer>
        </StyledHeader>
    );
};

export default Header;
