import React, { useEffect, useState, Component } from "react";

//import redux
// import { useSelector, useDispatch } from "react-redux";
// import { Creators as AppActions } from "../../../store/ducks/app";

import ReactSVG from "react-svg";
import { Navigate, Link } from "react-router-dom";

import Logo from "../../../assets/images/logo-lume.png";
import IconGoogle from "../../../assets/images/svg/google.svg";
import IconError from "../../../assets/images/svg/icon-error.svg";
import IconCheck from "../../../assets/images/svg/icon-check.svg";

import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

function ValidateRecover(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default function Recover(props) {
    const passwordInput = React.createRef();
    const repeatPasswordInput = React.createRef();

    // const dispatch = useDispatch();
    // const [pass, setPass] = useState('');
    // const [repeatPass, setRepeatPass] = useState('');
    const [textRecover, setTextRecover] = useState("Atualizar senha");
    // const [classPass, setClassPass] = useState('');
    const [redirectLogin, setRedirectLogin] = useState(false);

    function handleSubmit(event) {
        event.preventDefault();

        const pass = passwordInput.current.value;
        const repeatPass = repeatPasswordInput.current.value;

        if (pass == "") {
            // dispatch(AppActions.showNotification("Por favor, preencha a nova senha.", "warning"));
            // setClassPass('e-error');
            return;
        } else if (pass != repeatPass) {
            // dispatch(AppActions.showNotification("As senhas não coincidem.", "warning"));
            // setClassPass('e-error');
            return;
        }

        setTextRecover("Recuperando...");
        fetch("https://app.tarefy.com/api/auth/recover/pass", {
            method: "PUT",
            body: JSON.stringify({
                pass: pass,
                auth: props.match.params.hash,
                user: props.match.params.userid
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == true) {
                    // dispatch(AppActions.showNotification(responseJson.message, "success"));
                    // setClassPass('e-success');
                    setTextRecover("Senha atualizada");
                    setRedirectLogin(true);
                } else {
                    // dispatch(AppActions.showNotification(responseJson.message, "error"));
                    // setClassPass('e-error');
                    setTextRecover("Atualizar senha");
                }
            });
    }

    return (
        <div className="e-modal-login e-recover">
            <div className="e-header-login">
                <Header logoOnly logoAlignment="left" />
            </div>

            <div className="e-content">
                <h2>Nova senha</h2>
                <form onSubmit={handleSubmit}>
                    {/* <label className="e-label"> */}
                    {/* <span className="e-tt">Nova senha</span> */}
                    <Input label="Nova senha" ref={passwordInput} />

                    {/* <input
                            type="password"
                            name="pass"
                            className={classPass}
                            value={pass}
                            onChange={(e) => setPass(e.target.value)} /> */}
                    {/* </label> */}

                    {/* <label className="e-label"> */}
                    {/* <span className="e-tt">Repita a senha</span> */}
                    <Input label="Repita a senha" ref={repeatPasswordInput} />
                    {/* <input
                            type="password"
                            name="repass"
                            className={classPass}
                            value={repeatPass}
                            onChange={(e) => setRepeatPass(e.target.value)} /> */}
                    {/* </label> */}

                    <Button value={textRecover} />
                </form>
            </div>

            {redirectLogin ? <Navigate to={`/login`} /> : null}
        </div>
    );
}
