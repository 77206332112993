import React, {Component} from 'react'
import { Link } from 'react-router-dom'

//partials
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'

import Form from './Form'
import Partners from '../../Home/components/Partners'

//styles
import './styles.scss'

export class Contact extends Component {
    render() {
        return (
            <>
                <main className="e-main-page e-page-contact">

                    <Header />

                    <section className="e-contact">
                        <div className="e-contact__container">
                            <h1 className="e-title">Fale Conosco</h1>

                            <div className="e-contact__boxs">
                                <p>Precisa de mais informações ou quer experimentar um de nossos planos pagos? Envie suas informações e um representante do Tarefy entrará em contato com você o mais brevemente possível. <strong>Tem alguma dúvida rápida? Verifique a seção de perguntas frequentes.</strong></p>
                            </div>

                            <Form />

                            <Partners title="Temos a confiança de grandes equipes" />
                        </div>
                    </section>
                    
                    <Footer />

                </main>
            </>
        )
   }
};

export default Contact;
