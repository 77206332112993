import styled from "styled-components";
import { color, columnBreakPoint } from "../../globalStyleVariables";

export const HeaderContainer = styled.header`
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    background-color: #fff;
    z-index: 999;
    transition: box-shadow 0.3s ease-in-out;
`;

export const Logo = styled.div`
    display: inline-block;
    vertical-align: middle;

    a {
        font-size: 28px;
        font-weight: 900;
        color: ${color.main};
        transition: color 0.2s ease-in-out;

        &:hover {
            color: ${color.mainHover};
        }
    }
`;

export const StyledBurger = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background: ${({ open }) => (open ? color.white : color.title)};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? "0" : "1")};
            transform: ${({ open }) => (open ? "translateX(10px)" : "translateX(0)")};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
        }
    }

    @media only screen and (min-width: ${columnBreakPoint + 100}px) {
        display: none;
    }
`;

export const StyledMenu = styled.nav`
    display: flex;
    background: ${color.title};
    height: 100vh;
    align-items: flex-start;
    padding: 5rem 2rem;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;

    /* keep it closed */
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};

    @media only screen and (max-width: ${columnBreakPoint + 100}px) {
        width: 65%;
        flex-direction: column;

        .e-header__account {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;
            margin-top: 3rem;
        }

        ul li {
            padding: 1rem 0;
        }
    }

    @media only screen and (max-width: 740px) {
        width: 80%;
    }

    ul {
        display: flex;
        flex-direction: column;

        li {
            margin-bottom: 1rem;

            a {
                position: relative;
                font-size: 2rem;
                /* text-transform: uppercase; */
                padding: 2rem 0;
                font-weight: bold;
                color: ${color.background} !important;
                text-decoration: none;

                @media (max-width: ${columnBreakPoint + 100}px) {
                    font-size: 1.5rem;
                    text-align: center;
                }

                &:hover {
                    color: ${color.main};
                }
            }
        }
    }

    @media only screen and (max-width: 1050px) {
        ul {
            right: 40% !important;
            align-items: center;
        }
    }

    /* desktop */
    @media only screen and (min-width: ${columnBreakPoint + 100}px) {
        display: flex;
        background: transparent;
        text-align: left;
        padding: 0;
        height: unset !important;
        position: relative;
        transition: transform 0.3s ease-in-out;
        transform: none;

        ul {
            position: relative;
            flex-direction: row;
            right: 50%;
            transform: translateX(50%);

            li {
                padding: 0 1.75rem;
                margin: 0;

                a {
                    color: ${color.title} !important;
                    font-size: 1.1rem;
                    transition: color 0.2s linear;

                    &::after {
                        content: "";
                        position: absolute;
                        border-radius: 50px;
                        width: 0;
                        height: 4px;
                        background: ${color.main};
                        right: 0;
                        bottom: 25%;
                        transition: 0.2s ease-in-out;
                    }

                    &:hover {
                        color: ${color.main} !important;

                        &::after {
                            left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;

export const HeaderAccount = styled.div`
    display: flex;
    align-items: center;

    .e-header__account--login {
        font-size: 1rem;
        font-weight: bold;
        color: ${color.main};
        display: inline-block;
        transition: 0.2s ease-in-out;

        &:hover {
            text-decoration: underline;
            color: ${color.mainHover};
        }
    }

    .e-header__account--go-register {
        width: 150px;
        margin-left: 2rem;

        @media only screen and (max-width: ${columnBreakPoint + 100}px) {
            width: 100%;
            margin-left: 0;
            margin-top: 1rem;
        }
    }
`;
