import React, { useState } from "react";

import { Link } from "react-router-dom";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";

import BgTeams from "../../../../assets/images/site/bg-tarefy-times.png";

function InitialPage() {
    const [error, setError] = useState({ email: false });
    const emailRef = React.createRef();

    const submitStepZero = (e) => {
        e.preventDefault();

        const email = emailRef.current.value;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid = emailRegex.test(String(email).toLowerCase());

        if (isEmailValid) {
            localStorage.setItem("userEmail", email);

            window.location.href = "/register";
        } else {
            setError((prev) => ({ ...prev, email: true }));
        }
    };

    return (
        <section className="e-onboard">
            <div className="e-onboard__container">
                <div className="e-onboard__text">
                    <h1>
                        A ferramenta de <span>gestão</span> que sua empresa precisa.
                    </h1>
                    <p>Organize os projetos de sua empresa. De flexibilidade a seus times e execute as tarefas de forma descomplicada e simples.</p>
                </div>

                <div className="e-onboard__bg">
                    <img src={BgTeams} alt="Quadro Gestão de Tarefas - Tarefy" />
                </div>
            </div>

            <div className="e-onboard__register">
                <div className="e-onboard__register--description">
                    <h4>Crie sua conta grátis</h4>
                    <p>Faça um teste por 30 dias, e após o periodo escolha um plano de acordo com sua necessidade.</p>
                </div>

                <form>
                    <div className="e-form-group">
                        <Input label="email@empresa.com" className={`e-form-group__field ${error.email && "error"}`} type="email" ref={emailRef} />

                        <Button value="Começar" className="e-form-group__submit" size="auto" cb={submitStepZero} />
                    </div>

                    <p className="e-form-group__terms">
                        Ao criar minha conta, aceito os{" "}
                        <Link to="/institucional/termos-e-condicoes" className="text-primary">
                            Termos e Condições.
                        </Link>
                    </p>
                </form>
            </div>
        </section>
    );
}

export default InitialPage;
