import React from "react";
import { StyledMenu, HeaderAccount } from "../../styles";
import { Link } from "react-router-dom";
import Button from "../../../Button";

function Menu({ open }) {
   return (
      <StyledMenu className="e-header__menu" open={open}>
         <ul>
            <li>
               <Link to="/">Home</Link>
            </li>

            <li>
               <Link to="/recursos">Recursos</Link>
            </li>

            <li>
               <Link to="/planos">Planos</Link>
            </li>

            <li>
               <Link to="/institucional/contato">Fale Conosco</Link>
            </li>
         </ul>

         <HeaderAccount className="e-header__account">
            <a href="https://app.tarefy.com/login" className="e-header__account--login">
               Fazer Login
            </a>

            <Link to="/register" className="e-header__account--go-register">
               <Button value="Comece já" mobile="padding: 15px 0;" />
            </Link>
         </HeaderAccount>
      </StyledMenu>
   );
}

export default Menu;
