import React, { Component } from "react";

//styles
import "./styles.scss";

// components
import Resources from "./components/Resources";
import Partners from "./components/Partners";
import Teams from "./components/Teams";
import InitialPage from "./components/InitialPage";

//partials
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Home = () => {
   return (
      <main className="e-main-page e-home">
         <Header />

         <InitialPage />
         <Teams />
         <Resources />
         <Partners />

         <Footer />
      </main>
   );
};

export default Home;
