import React, { useState } from "react";

import { Container } from "./styles";

import BgLogin from "../../assets/images/site/bglogin.png";
import axios from "axios";
import { Snackbar } from "@mui/material";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RegisterGlobal(props) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [notification, setNotification] = useState(null);

    const register = (e) => {
        e.preventDefault()

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(!emailRegex.test(email)) {
            setNotification({message: 'Formato de email incorreto.', type: 'error'})
            return;
        }

        if(password !== repeatPassword) {
            setNotification({message: 'As senhas não estão iguais.', type: 'error'})
            return;
        }

        if(password.length < 8) {
            setNotification({message: 'A senha deve conter no minimo 8 caracteres.', type: 'error'})
            return;
        }
    
        if(password === repeatPassword && emailRegex.test(email) && password.length > 7) {
            axios.post(`${process.env.REACT_APP_HOST}/auth/createaccount/global`, {
                name: name,
                email: email,
                password: password,
                repeatPassword: repeatPassword
            }).then((response) => {
                if(response.data.status === true) {
                    window.location.href = `https://app.tarefy.com/login`;
                }
            })
            .catch((error) => {
                setNotification({message: error.response.data, type: 'error'});
            });
        }
    }

    function handleClose(){
		setNotification(null);
	}

    return (
        <Container>
            <div className="e-registerGlobalLeft">
                <span className="e-registerGlobalLeft-logo">Tarefy</span>

                <div className="e-registerGlobalLeft-container">
                    <h3>Criar uma conta</h3>
                    <p>Faça seu cadastro para acessar o Tarefy</p>

                    <form onSubmit={(e) => register(e)}>
                        <label>Nome</label>
                        <input placeholder="Insira seu nome" onChange={(e) => setName(e.target.value)}></input>

                        <label>E-mail</label>
                        <input placeholder="Insira seu e-mail" onChange={(e) => setEmail(e.target.value)}></input>

                        <label>Senha</label>
                        <input placeholder="Insira sua senha" type="password" onChange={(e) => setPassword(e.target.value)}></input>

                        <label>Repetir senha</label>
                        <input placeholder="Insira novamente sua senha" type="password" onChange={(e) => setRepeatPassword(e.target.value)}></input>

                        <button>Registrar</button>
                    </form>
                    <span>Já tem uma conta?<a href="https://app.tarefy.com/login"> Faça login</a></span>
                </div>
            </div>
            <div className="e-registerGlobalRigth">
                <img src={BgLogin} alt="gb"></img>
                <h3>Organize, colabore e simplifique sua gestão de tarefas</h3>
                <span>Transforme sua maneira de trabalhar com o Tarefy para empresas!</span>
            </div>
            {notification ? 
                <Snackbar open={true} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                    <div>
                        <Alert onClose={handleClose} severity={notification.type} style={{color: 'white'}}>
                            <div style={{color: 'white'}}>
                                {notification.message}
                            </div>
                        </Alert>
                    </div>
                </Snackbar>
            : null}
        </Container>
    );
}

export default RegisterGlobal;
