import { BrowserRouter as Router,  Routes, Route } from "react-router-dom";

import ScrollIntoView from "./ScrollToTop";

//style
import "./assets/scss/lume-app.scss";

//pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterValidateMail from "./pages/Register/Steps/ValidateMail";

import Recover from "./pages/Login/Recover";
import ValidateRecover from "./pages/Login/ValidateRecover";
// import CompleteRegistration from "./pages/Login/CompleteRegistration";
import Resources from "./pages/Resources";
import Plans from "./pages/Plans";
import Contact from "./pages/Institutional/Contact";
import TermsAndCondition from "./pages/Institutional/Terms-and-Conditions";
import PoliceAndPrivate from "./pages/Institutional/Police-and-Private";
import QuestionsCommon from "./pages/Institutional/Questions-Common";
import VerifyEmail from "./pages/Register/Steps/VerifyEmail";
import RegisterGlobal from "./pages/RegisterGlobal/RegisterGlobal";
// import NotFound from './pages/404';


export default function App() {

  return (
    <Router>
        <ScrollIntoView>
          <div className="e-site">
            <Routes>
                <Route path="/" exact element={<Home />} />
                {/* <Route path="/register" exact element={<Register />} />
                <Route path="/register/validatemail/:userid/:hash" exact element={<RegisterValidateMail />} /> */}
                <Route path="/confirm/:userid/:hash" exact element={<VerifyEmail />} />
                {/* <Route
                  path="/register/validate/:userid/:hash"
                  exact
                  element={<CompleteRegistration />}
                /> */}
                <Route path="/register" exact element={<RegisterGlobal />} />
                <Route path="/recursos" exact element={<Resources />} />
                <Route path="/planos" exact element={<Plans />} />
                <Route path="/institucional/contato" exact element={<Contact />} />
                <Route
                  path="/institucional/termos-e-condicoes"
                  exact
                  element={<TermsAndCondition />}
                />
                <Route
                  path="/institucional/politicas-de-privacidade"
                  exact
                  element={<PoliceAndPrivate />}
                />
                <Route
                  path="/institucional/perguntas-frequentes"
                  exact
                  element={<QuestionsCommon />}
                />



                <Route path="/login" exact element={<Login />} />
                <Route path="/login/recover" exact element={<Recover />} />
                <Route
                  path="/login/recover/:userid/:hash"
                  exact
                  element={<ValidateRecover />}
                />
            </Routes>
          </div>
        </ScrollIntoView>
    </Router>
  );
}

// export default App;
