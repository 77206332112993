import React, { useState } from "react";

import { InputContainer, TextInput, Label, FocusBorder, StyledTextarea, InputOuterContainer } from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Input = React.forwardRef((props, ref) => {
    let { label, type, required, className, cb, textarea, autofocus, defaultValue, name } = props;
    const [inputLength, setInputLength] = useState(0);
    const [passwordView, setPasswordView] = useState(false);

    let tempType = type;

    if (type === "password") passwordView ? (tempType = "text") : (tempType = "password");

    return (
        <InputOuterContainer active={passwordView} className="input-container">
            <InputContainer>
                {textarea ? (
                    <StyledTextarea
                        className={`${inputLength && "has-content"} input ${className && className}`}
                        spellcheck="false"
                        onChange={(e) => (setInputLength(e.target.value.length), cb && cb(e))}
                        ref={ref}
                        required={required || true}
                    />
                ) : (
                    <TextInput
                        type={tempType || "text"}
                        className={`${(inputLength || defaultValue?.length) && "has-content"} input ${className && className}`}
                        spellcheck="false"
                        onChange={(e) => (setInputLength(e.target.value.length), cb && cb(e))}
                        ref={ref}
                        required={required || true}
                        autoFocus={autofocus || false}
                        name={name || ''}
                        defaultValue={defaultValue}
                        style={{ borderRadius: type === "password" ? "5px 0 0 5px" : null }}
                    />
                )}
                <Label className={className} textarea={textarea}>
                    {label}
                </Label>
                <FocusBorder>
                    <i></i>
                </FocusBorder>
            </InputContainer>

            {type === "password" && (
                <div className="password-view__container" onClick={() => setPasswordView((prev) => !prev)}>
                    {passwordView ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </div>
            )}
        </InputOuterContainer>
    );
});

export default Input;
