import React from "react";

import { StyledButton } from "./styles";

function Button({ value, children, size, radius, cb, mobile, ...rest }) {
    return (
        <StyledButton size={size} radius={radius} onClick={(e) => cb && cb(e)} mobile={mobile} {...rest}>
            {value || children || ""}
        </StyledButton>
    );
}

export default Button;
