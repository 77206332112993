import React, { useEffect, useState, Component } from "react";

//import redux
// import { useSelector, useDispatch } from "react-redux";
// import { Creators as AppActions } from "../../../store/ducks/app";

import Button from "../../../components/Button";

// style
import { FormContainer } from "./styles";

export default function Form() {
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    // // const dispatch = useDispatch();

    const [nome, setNome] = useState([]);
    const [email, setEmail] = useState([]);
    const [telefone, setTelefone] = useState([]);
    const [mensagem, setMensagem] = useState([]);

    function handleSubmit(event) {
        event.preventDefault();
        var _this = this;
        if (nome == "" || email == "" || telefone == "" || mensagem == "") {
            // dispatch(AppActions.showNotification("Por favor, preencha todos os campos.", "error"));
            return;
        } else if (!validateEmail(email)) {
            // dispatch(AppActions.showNotification("Email inválido.", "error"));
            return;
        } else {
            fetch("https://app.tarefy.com/api/contact/", {
                method: "PUT",
                body: JSON.stringify({
                    nome: nome,
                    email: email,
                    telefone: telefone,
                    mensagem: mensagem
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status) {
                        // dispatch(AppActions.showNotification("Mensagem enviada com sucesso, em breve entraremos em contato."));
                    } else {
                        // dispatch(AppActions.showNotification(responseJson.message, "error"));
                    }
                });
        }
    }

    return (
        <FormContainer className="e-contact__form">
            <div className="e-center">
                <form className="e-form" onSubmit={handleSubmit}>
                    <label>
                        <span className="e-tt">Nome</span>
                        <input type="text" name="nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </label>

                    <label>
                        <span className="e-tt">E-mail</span>
                        <input type="text" name="email" placeholder="nome@empresa.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>

                    <label>
                        <span className="e-tt">Telefone</span>
                        <input
                            type="text"
                            name="telefone"
                            placeholder="(99) 99999-9999"
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                        />
                    </label>

                    <label>
                        <span className="e-tt">Mensagem</span>
                        <textarea name="mensagem" value={mensagem} onChange={(e) => setMensagem(e.target.value)}></textarea>
                    </label>

                    <Button value="Enviar" />
                </form>
            </div>
        </FormContainer>
    );
}
