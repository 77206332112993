import styled from "styled-components";
import { color } from "../../../globalStyleVariables";

export const Container = styled.div`
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    align-items: center;

    /* @media only screen and (max-width: 740px) {
        display: none;
    } */
`;

const checkSize = "25px";
const contentSpacing = 35;

export const ProgressCheck = styled.div`
    position: relative;
    width: ${checkSize};
    height: ${checkSize};
    border-radius: 50%;
    background: ${({ active }) => (active ? color.success : color.white)};
    transition: 0.75s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "${({ content }) => content}";
        position: absolute;
        top: ${({ top, bottom }) => (top && -contentSpacing + "px") || (bottom && contentSpacing + "px")};

        /* font-size: 1.2rem; */
        color: ${({ active }) => (active ? color.white : "rgba(255, 255, 255, 0.5)")};
        white-space: nowrap;
        transition: 0.35s ease-in-out;
    }

    .progress__done-icon {
        opacity: ${({ active }) => (active ? 1 : 0.75)};
        transition: 2s ease-in-out;
        font-size: 1.15rem;
    }
`;

export const Line = styled.div`
    position: relative;
    width: 90px;
    height: 4px;
    background: rgba(255, 255, 255, 0.35);

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: ${({ active }) => active && "90px"};
        height: 4px;
        background: ${color.success};

        transition: 0.5s ease-in-out;
    }

    @media only screen and (max-width: 1400px) {
        width: 60px;
    }
`;
