import React, { Component } from 'react';
import { Link } from 'react-router-dom'

//styles
import './styles.scss'

export default function Footer() {
    class FooterYear extends React.Component {
        render() {
            return (new Date().getFullYear());
        }
    }

    return (
            <>
                <div className="e-footer">

                    <div className="e-footer__container">

                        <div className="e-footer__columns">
                            <div className="e-footer__columns--items">
                                <h5 className="e-logo-footer">Tarefy</h5>

                                <p className="e-description-footer">
                                    Uma gestão eficiente dos processos de sua empresa<br /> começa com o Tarefy. gerencie projetos,<br /> clientes, tarefas, times e muito mais!
                                </p>
                            </div>

                            <div className="e-footer__columns--items">
                                <h4>Sobre</h4>

                                <ul>
                                    <li>
                                        <Link to="/recursos">Recursos</Link>
                                    </li>

                                    <li>
                                        <Link to="/planos">Planos</Link>    
                                    </li>

                                    <li>
                                        <Link to="/login">Login</Link>
                                    </li>

                                    <li>
                                        <Link to="/institucional/termos-e-condicoes">Termos e Condições</Link>
                                    </li>

                                    <li>
                                        <Link to="/institucional/politicas-de-privacidade">Politicas de Privacidade</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="e-footer__columns--items">
                                <h4>Suporte</h4>

                                <ul>
                                    <li>
                                        <Link to="/contato">Central de Ajuda</Link>    
                                    </li>
                                    
                                    <li>
                                        <Link to="/institucional/contato">Fale Conosco</Link>
                                    </li>

                                    <li>
                                        <Link to="/institucional/perguntas-frequentes">Perguntas Frequentes</Link>  
                                    </li>
                                </ul>
                            </div>

                            <div className="e-footer__columns--items">
                                <h4>Endereço</h4>

                                <ul>
                                    <li>
                                        Av Andrômeda, 885 - Barueri, SP<br />
                                        3º Andar - Sala 302<br />
                                        CEP: 06473-000<br /><br />
                                        Tel. 11 96731-4400<br />
                                        comercial@tarefy.com
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="e-footer-sub">
                    <div className="e-footer-sub__container">
                        <FooterYear /> © Tarefy - Todos os direitos reservados
                    </div>
                </div>
            </>
    )
}
