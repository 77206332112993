import React, { useState } from "react";

//import redux
// import { useDispatch } from "react-redux";
// import { Creators as AppActions } from "../../store/ducks/app";

import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";

import Header from "../../components/Header";
import Input from "../../components/Input";

//styles
import "./styles.scss";

// images
// import Logo from '../../assets/images/logo-lume.png';
import IconGoogle from "../../assets/images/svg/google.svg";
import ServiceDevices from "../../assets/images/site/banner-devices-app.png";

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default function Login(props) {
    // // const dispatch = useDispatch();

    const emailRef = React.createRef();
    const passwordRef = React.createRef();

    const [error, setError] = useState({
        email: false,
        password: false
    });
    const [textLogin, setTextLogin] = useState("Entrar");

    function handleSubmit(event) {
        event.preventDefault();

        var email = emailRef.current.value;
        var password = passwordRef.current.value;

        if (email == "" || !validateEmail(email)) {
            setError((prev) => ({ ...prev, email: true }));
            return;
        }

        if (password == "") {
            setError((prev) => ({ ...prev, password: true }));
            return;
        }

        fetch("https://app.tarefy.com/api/auth", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == true) {
                    setTextLogin("Iniciando sessão...");
                    window.location.href = "/app/dashboard";
                } else {
                    const _message = responseJson.message;
                    // dispatch(AppActions.showNotification(_message, "error"));
                }
            });
    }

    function responseGoogle(response) {
        const _emailLogin = response.profileObj.email;

        fetch("https://app.tarefy.com/s/login/google", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: _emailLogin
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == true) {
                    setTextLogin("Iniciando sessão...");
                    window.location.href = "/app/todo";
                } else {
                    const _message = responseJson.message;
                    // dispatch(AppActions.showNotification(_message, "error"));
                }
            });
    }

    return (
        <div className="e-page-login">
            <div className="e-page-login__container">
                <Header logoOnly />

                <div className="e-box-bg">
                    <img src={ServiceDevices} alt="Quadro Gestão de Tarefas - Tarefy" />
                </div>

                <div className="e-box-login">
                    <div className="e-box-login__container">
                        <div className="e-box-login__header">
                            <h3 className="e-title">Faça seu login</h3>
                        </div>

                        <GoogleLogin
                            clientId="210329786994-qj0ce9q9h3lvarnpitahu02p5ko64qn2.apps.googleusercontent.com"
                            render={(renderProps) => (
                                <button className="e-login-google" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                    {/* <ReactSVG src={IconGoogle} /> */}
                                    Usar uma conta do Google
                                </button>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                        />

                        <div className="e-spacer">ou</div>

                        <form className="e-form-login" onSubmit={handleSubmit}>
                            <label className="e-label">
                                <Input label="E-mail" type="email" name="email" ref={emailRef} className={`${error.email ? "error" : ""} has-content`} autofocus />
                            </label>

                            <label className="e-label">
                                <Input label="Senha" type="password" ref={passwordRef} className={`${error.password ? "error" : ""} has-content`} />
                            </label>

                            <button type="submit" className="e-btn-login">
                                {textLogin}
                            </button>
                        </form>

                        <div className="e-links">
                            <Link to="/login/recover" className="e-forget">
                                Esqueceu sua senha?
                            </Link>

                            <h6>
                                Não tem uma conta? <Link to="/register">Cadastre-se</Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
