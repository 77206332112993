import React, { useEffect, useState, Component } from "react";

//import redux
// import { useSelector, useDispatch } from "react-redux";
// import { Creators as AppActions } from "../../../store/ducks/app";

import ReactSVG from "react-svg";
import { Redirect, Link } from "react-router-dom";

// styles
import "./styles.scss";

// images
import Logo from "../../../assets/images/logo-lume.png";
import IconGoogle from "../../../assets/images/svg/google.svg";
import IconError from "../../../assets/images/svg/icon-error.svg";
import IconCheck from "../../../assets/images/svg/icon-check.svg";

import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default function Recover(props) {
    // const dispatch = useDispatch();

    const emailInput = React.createRef();

    // const [email, setEmail] = useState("");
    const [textRecover, setTextRecover] = useState("Recuperar senha");
    const [classMail, setClassMail] = useState("");

    // function handleBlur(event) {
    //     var _valid = validateEmail(email);
    //     if (!_valid) setClassMail("e-error");
    //     else setClassMail("e-success");
    // }

    function handleSubmit(event) {
        event.preventDefault();

        const email = emailInput.current.value;

        let _valida = true;

        if (!email || !validateEmail(email)) {
            // setClassMail("e-error");
            _valida = false;
        } else {
            // setClassMail("e-success");
        }

        if (_valida) {
            setTextRecover("Recuperando...");
            fetch("https://app.tarefy.com/api/auth/recover", {
                method: "PUT",
                body: JSON.stringify({
                    email: email
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == true) {
                        // dispatch(AppActions.showNotification(responseJson.message, "success"));
                    } else {
                        // dispatch(AppActions.showNotification(responseJson.message, "error"));
                        // setClassMail("e-error");
                    }
                    setTextRecover("Recuperar senha");
                });
        }
    }

    return (
        <div className="e-page-recovery-password">
            <div className="e-page-recovery-password__container">
                <div className="e-box-recovery-password">
                    <div className="e-box-recovery-password__header">
                        <Header logoOnly logoAlignment="left" />

                        <h2>Recuperar senha</h2>
                    </div>

                    <div className="e-box-recovery-password__content">
                        <form onSubmit={handleSubmit}>
                            <label className="e-label">
                                {/* <span className="e-tt">Endereço de e-mail</span> */}
                                <Input type="email" label="nome@empresa.com" ref={emailInput} />
                            </label>

                            <Button value={textRecover} />

                            <Link to="/login" className="e-back">
                                Voltar para o login
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
