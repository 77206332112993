import React, {Component} from 'react'
import { Link } from 'react-router-dom'

//partials
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'

//styles
import './styles.scss'

export class QuestionsCommon extends Component {
    render() {
        return (
            <>
                <main className="e-main-page e-page-questions-common">

                    <Header />

                    <section className="e-questions-common">
                        <div className="e-questions-common__container">
                            <h1 className="e-title">Perguntas Frequentes</h1>

                            <div className="e-box-questions-common">
                                <div className="e-box-question">
                                    <h4>1. Como Criar uma conta no Tarefy?</h4>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet. Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                </div>

                                <div className="e-box-question">
                                    <h4>2. Como Escolher um plano?</h4>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet. Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                </div>

                                <div className="e-box-question">
                                    <h4>3. O Tarefy é gratuito?</h4>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet. Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                </div>

                                <div className="e-box-question">
                                    <h4>4. Esqueci minha senha, como recuperar?</h4>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet. Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                </div>

                                <div className="e-box-question">
                                    <h4>5. Como entrar em contato com o Tarefy?</h4>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet. Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <Footer />

                </main>
            </>
        )
   }
};

export default QuestionsCommon;
