import React, { Component } from "react";
import { Link } from "react-router-dom";

//partials
import Header from "../../components/Header";
import Footer from "../../components/Footer";

//styles
import "./styles.scss";

// images
import QuadroPeople from "../../assets/images/site/quadro-people.gif";

// components
import Cards from "./Cards";

export class Resources extends Component {
    render() {
        return (
            <>
                <main className="e-main-page e-page-resources">
                    <Header />

                    <section className="e-resources">
                        <div className="e-resources__container">
                            <h1 className="e-title">Conheça alguns recursos</h1>

                            <div className="e-resources__boxs">
                                <Cards />
                            </div>
                        </div>
                    </section>

                    <Footer />
                </main>
            </>
        );
    }
}

export default Resources;
