import React from "react";
import { Link } from "react-router-dom";

import { PartnersContainer } from "./styles";

import LogoEconverse from "../../../../assets/images/site/logo-econverse.png";
import LogoGuilu from "../../../../assets/images/site/logo-guilu.png";
import LogoE4u from "../../../../assets/images/site/logo-e4u.png";
import LogoRoyalpixel from "../../../../assets/images/site/logo-royalpixel.png";

function Partners({ title }) {
    const partners = [
        {
            logo: LogoEconverse,
            alt: "Logo Econverse",
            url: "http://econverse.digital/"
        },
        {
            logo: LogoRoyalpixel,
            alt: "Logo LogoRoyalpixel",
            url: "https://royalpixel.tv/"
        },
        {
            logo: LogoE4u,
            alt: "Logo LogoE4u",
            url: "http://www.e4u.com.br/site3/"
        },
        {
            logo: LogoGuilu,
            alt: "Logo LogoGuilu",
            url: "https://www.guilu.com.br/"
        }
    ];

    return (
        <PartnersContainer className="e-partners">
            <div className="e-partners__container">
                <h3 className="e-title">{title || "Veja quem usa o tarefy"}</h3>
                <p>Junte-se a mais de 100 parceiros, que hoje tornam processos mais eficientes no dia a dia de suas equipes.</p>

                <div className="e-partners__boxs">
                    {partners.map(({ logo, alt, url }, i) => (
                        <div className="e-box" key={i}>
                            <Link to={url} target="_blank">
                                <img src={logo} alt={alt} />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </PartnersContainer>
    );
}

export default Partners;
