import styled from "styled-components";

import { columnBreakPoint } from "../../../globalStyleVariables";

export const FormContainer = styled.section`
   width: 100%;
   height: 100%;

   .e-form {
      width: 50%;
      display: block;
      margin-top: 30px;

      @media only screen and (max-width: ${columnBreakPoint + 100}px) {
         width: 100%;
      }

      label {
         width: 100%;
         display: block;
         margin-bottom: 20px;

         .e-tt {
            font-size: 14px;
            color: #333;
            display: block;
            font-weight: 500;
            margin-bottom: 5px;
         }

         input,
         textarea {
            width: 100%;
            display: block;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
         }

         textarea {
            max-height: 300px;
            resize: vertical;
            min-height: 150px;
         }
      }
   }
`;
