import styled from "styled-components";
import { Link } from "react-router-dom";

import { color } from "../../../../globalStyleVariables";

export const PartnersContainer = styled.section`
    background-color: #f6f7fa;
    padding: 80px 0 100px;

    .e-partners__container {
        max-width: 1140px;
        margin: 0 auto;
    }

    .e-title {
        font-size: 38px;
        font-weight: bold;
        color: ${color.title};
        line-height: 1.2;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        line-height: 1.5;
        color: ${color.text};
        text-align: center;
        margin-bottom: 20px;
    }

    .e-partners__boxs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 70px;

        .e-box {
            img {
                max-width: 100%;
                max-width: 180px;
                height: auto;
                max-height: 80px;
                vertical-align: middle;
            }
        }
    }
`;
