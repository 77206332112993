import React from "react";

import { Link } from "react-router-dom";
import TeamsPeople from "../../../../assets/images/site/teams-seo.svg";

function Teams() {
    return (
        <section className="e-teams">
            <div className="e-teams__container">
                <div className="e-teams__text">
                    <h2>
                        Trabalhe com qualquer <span>time</span> e <span>projeto</span>
                    </h2>

                    <p>
                        Simplifique a forma como a sua equipe trabalha. Dê eficiencia aos projetos de sua empresa, e tenha uma visão ampla de seu
                        negócio.
                    </p>

                    <Link to="/register">
                        Começar <span>›</span>
                    </Link>
                </div>

                <div className="e-teams__bg">
                    <img src={TeamsPeople} alt="Quadro Gestão de Tarefas - Tarefy" />
                </div>
            </div>
        </section>
    );
}

export default Teams;
