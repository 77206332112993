import styled from "styled-components";

export const Container = styled.div`

    @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
    
    display: flex;
    
    .e-registerGlobalLeft {
        width: 50%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1000px) {
            width: 100%;
        }
        &-logo {
            font-size: 40px;
            font-family: Lobster;
            color:  #2F55D4;
            position: absolute;
            top: 20px;
            left: 20px;
        }


        &-container {
            width: 420px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 500px) {
                width: 80%;
            }
            > span {
                color: #979899;
                font-size: 12px;
                margin-top: 5px;
                text-align: center;
                a {
                    text-decoration: none;
                    color: #466AE0;
                }
            }
        }

        h3 {
            color: #14181F;
            font-size: 28px;
            font-weight: 800;
            @media (max-width: 500px) {
                font-size: 22px;
            }
        }

        p {
            color: #4D4D4D;
            font-size: 16px;
            @media (max-width: 500px) {
                font-size: 14px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            label {
                color: #14181F;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input {
                border: 1px solid #EFEFF5;
                padding: 12px 16px 12px 16px;
                color: #828282;
                margin-bottom: 20px;
                border-radius: 4px;
            }

            button {
                background-color: #2F55D4;
                color: #fff;
                width: 100%;
                height: 48px;
                font-size: 14px;
                font-weight: 700;
                cursor: pointer;
                border-radius: 4px;
            }
        }
    }

    .e-registerGlobalRigth {
        width: 50%;
        height: 100vh;
        background-color: #2F55D4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 1000px) {
            display: none;
        }
        img {
            width: 100%;
        }
        h3 {
            color: #fff;
            font-weight: 800;
            color: 24px;
            max-width: 350px;
            text-align: center;
        }
        span {
            color: #fff;
            font-size: 16px;
            max-width: 380px;
            text-align: center;
        }
    }
`;
